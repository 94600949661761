import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UtilService } from './util.service';
import { AuthService } from './../auth/auth.service';
import { TokenService } from './../auth/token.service';
import { Observable, throwError } from 'rxjs';
import { CFG } from '../config';
import { WINDOW } from '../window-factory';

const maxAge = 30000;

@Injectable()
export class ApiService {
    cache = new Map();
    CFG = CFG;
    private ls: any;

    constructor(private httpClient: HttpClient,
        private utilService: UtilService,
        private authService: AuthService,
        private tokenService: TokenService,
        @Inject(WINDOW) private window: Window
    ) {
        this.ls = this.window.localStorage;
    }

    getCache(req: HttpRequest<any>): HttpResponse<any> | undefined {
        const url = req.urlWithParams;
        const cached = this.cache.get(url);

        if (!cached) {
            return undefined;
        }

        return cached.response;
    }

    putCache(req: HttpRequest<any>, response: HttpResponse<any>): void {
        const url = req.url;
        const entry = { url, response, lastRead: Date.now() };
        this.cache.set(url, entry);

        const expired = Date.now() - maxAge;
        this.cache.forEach(expiredEntry => {
            if (expiredEntry.lastRead < expired) {
                this.cache.delete(expiredEntry.url);
            }
        });
    }

    getBlob(url, params?, reqCfg = {}) {
        return this.get(url, params, {
            ...reqCfg,
            ...{
                responseType: 'blob',
                observe: 'response',
                runInBg: true,
                cache: false
            }
        } || {});
    }

    setCache(cacheKey, data) {
        data.expire = new Date().getTime() + maxAge;

        this.ls.setItem(cacheKey, JSON.stringify(data));
    }

    getCached(cacheKey) {
        return this.utilService.promise(JSON.parse(this.ls.getItem(cacheKey)));
    }

    isCached(cacheKey) {
        return this.ls.hasOwnProperty(cacheKey);
    }

    private _getRaw(method, params, reqCfg) {

        return this.httpClient.get<ApiResponse>(
            reqCfg.strictUrl && method || this.utilService.buildUrl(params, method, reqCfg.ctrlType, reqCfg.identifier),
            this.utilService.buildHttpRequestOptions(reqCfg)
        )
            .pipe(
                catchError((err: HttpErrorResponse) => this.onError(err, reqCfg)),
                map((resp) => this.processResponse(resp, method, reqCfg))
            );
    }

    private _checkLocalStorageSize() {
        const reqCfgCache = CFG.cache, self = this,
            cacheMaxAllowedSize = reqCfgCache.maxSize - reqCfgCache.offsetSize;

        // Clean all expired caches, that not contain main data
        if (cacheMaxAllowedSize <= this._getLocalStorageSize()) {
            Object.keys(this.ls).forEach((k) => {
                if (self._isExpiredCache(k)) {
                    self._deleteCache(k);
                }
            });
        }
    }

    private _deleteCache(cacheKey: string) {
        delete this.ls[cacheKey];
    }

    private _isExpiredCache(cacheKey) {
        return this.ls[cacheKey].expire < new Date().getTime();
    }

    private _getLocalStorageSize() {
        let t = 0;
        Object.keys(this.ls).forEach((k) => {
            t += this.ls[k].length * 2;
        });

        return t;
    }

    get(
        method,
        params: { [param: string]: string } = {},
        reqCfg = {}
    ): Observable<any> {
        const cacheEnabled = CFG.cache.enabled;

        if (typeof reqCfg['cache'] === 'undefined') {
            reqCfg['cache'] = true;
        }

        if (cacheEnabled) {
            this._checkLocalStorageSize();
        }

        const cacheName = reqCfg['cacheKey'] || method;

        if (cacheEnabled && reqCfg['cache'] && this.isCached(cacheName)) {
            return this.getCached(cacheName);
        } else {
            return this._getRaw(method, params, reqCfg);
        }
    }


    post(path: string,
        body: {} = {},
        params: { [param: string]: string } = {},
        letmc = true
    ): Observable<any> {
        return this.httpClient.post<ApiResponse>(
            this.utilService.buildUrl(params, path, 'postProperty', CFG.postPropertyIdentifier, letmc),
            JSON.stringify(body),
            this.utilService.buildHttpRequestOptions({})
        ).pipe(
            catchError((err: HttpErrorResponse) => this.onError(err, {})),
            map((resp: ApiResponse) => this.onSuccess(resp))
        );
    }


    // put(path: string,
    //     body: {} = {},
    //     params: { [param: string]: string } = {}
    // ): Observable<any> {
    //     return this.httpClient.put<ApiResponse>(
    //         this.utilService.buildUrl(path),
    //         JSON.stringify(body),
    //         this.utilService.buildHttpRequestOptions(params)
    //     ).pipe(
    //         catchError((err: HttpErrorResponse) => this.onError(err)),
    //         map((resp: ApiResponse) => this.onSuccess(resp))
    //     );
    // }

    // delete(path: string,
    //     params: { [param: string]: string } = {}): Observable<any> {
    //     return this.httpClient.delete<ApiResponse>(
    //         this.utilService.buildUrl(path),
    //         this.utilService.buildHttpRequestOptions(params)
    //     )
    //         .pipe(
    //             catchError((err: HttpErrorResponse) => this.onError(err)),
    //             map((resp: ApiResponse) => this.onSuccess(resp))
    //         );
    // }

    private checkTokenExpired(err: HttpErrorResponse, runInBg: boolean): boolean {
        if (err && err.status && (err.status === 401 || err.status === 403)) {
            // Logout user.
            this.authService.logout();

            return true;
        }

        if (!runInBg && err && err.status && (err.status === 404 || err.status === 500)) {

            this.authService.onError(CFG.ERROR.E005);
        }

        return false;
    }

    private processResponse(resp, cacheKey, reqCfg) {
        reqCfg = reqCfg || {};

        if (reqCfg.responseType === 'blob') {
            if (reqCfg.parseFileName) {
                const contentDispositionHeader = resp.headers.get('Content-Disposition');

                if (contentDispositionHeader) {
                    const matches = contentDispositionHeader.match(/filename="?(.+)"?/);
                    resp.fileName = matches && matches[1].replace(`"`, ``) || '';
                }
            }

            if (reqCfg.createObjectUrl) {
                resp.data = window.URL.createObjectURL(resp.body);
            }

            return resp;
        } else {
            if (CFG.cache.enabled && reqCfg.cache) {
                return this.onSuccess(resp, cacheKey, reqCfg['cacheKey']);
            } else {
                return this.onSuccess(resp);
            }
        }
    }

    private onSuccess(resp, cacheKey?: string, newCachekey?: string): Observable<ApiResponse> {
        // Replace old token to the new one if exists.
        if (resp.token) {
            this.tokenService.token = resp.token;
        }


        // Clear string response
        if (resp.data instanceof String) {
            resp.data = resp.data.replace(/"/g, '');
        }

        // save to localStorage
        if (newCachekey || cacheKey) {
            this.setCache(newCachekey || cacheKey, resp);
        }

        // return 'data' in resp && resp.data || resp.success;
        return resp;
    }

    private onError(err: HttpErrorResponse, reqCfgData): Observable<any> {
        reqCfgData = reqCfgData || {};

        // When token expired logout.
        this.checkTokenExpired(err, reqCfgData.runInBg);

        return throwError(err);
    }

}
