import { Component, OnInit } from '@angular/core';
import {
    ApiService,
    UtilService,
    Settings,
    SettingsService,
    AccountModel,
    ReportYears,
    ProfitLossModel,
    TenancyModel, PropertyService, SelfAssessment
} from '../core';
import { ScriptLoaderService, GoogleChartPackagesHelper } from 'angular-google-charts';
import { DatePipe } from '@angular/common';
import * as tableSettings from './tableSettings';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.less'],
    providers: [DatePipe]
})

export class AccountComponent implements OnInit {
    model: AccountModel;
    settings: Settings;
    reportYears: ReportYears;
    displayDownloadBtn = false;
    profitLossData;
    sas: any;
    data = {};
    ready = false;
    tableSettings = tableSettings;
    generalTenantsRentAmount: number = 0;
    generalTenantData: string;
    dateGeneral: any;
    greatestYear: string;
    selfAssessment: SelfAssessment[];
    selfAssessmentLoading = false;

    constructor(
        private apiService: ApiService,
        private settingsSvc: SettingsService,
        private propertyService: PropertyService,
        private datePipe: DatePipe,
        private utilService: UtilService,
        private loaderService: ScriptLoaderService
    ) { }

    ngOnInit() {
        this.settings = this.settingsSvc.get();

        this.reportYears = {
            selectedOption: null,
            availableOptions: []
        };

        this.apiService.get('accounting').subscribe(
            resp => {
                this.model = resp;

                if (this.settings.productSettings.enableProfitAndLossSection) {
                    this.buildProfitLoss();
                }

                // Set chart data
                this.loaderService.onReady.subscribe(() => {
                    const type = (GoogleChartPackagesHelper.getPackageForChartName('AreaChart')).split(', ');
                    this.loaderService.loadChartPackages(type).subscribe(() => {
                        this.data = {
                            // Build chart config object
                            paymentHistoryChart: {
                                type: 'AreaChart',
                                data: [],
                                columnNames: ['Description', 'Amount'],
                                options: {
                                    height: this.utilService.chartMaxHeight(500),
                                    maxHeight: 500,
                                    width: window.innerWidth <= 768 ? window.innerWidth - 30 : undefined, // 30 - this is padding
                                    isStacked: true,
                                    displayAnnotations: true,
                                    fill: 10,
                                    displayExactValues: true,
                                    colors: ['#' + (this.settings.color1 || '38b192')],
                                    chartArea: {
                                        width: '90%',
                                        height: '80%',
                                        top: 10,
                                        left: 40
                                    },
                                    bar: {
                                        groupWidth: '100%'
                                    },
                                    vAxis: {
                                        format: '£###,###',
                                        textStyle: {
                                            fontSize: 10
                                        },
                                        gridlines: {
                                            count: 6
                                        }
                                    },
                                    hAxis: {
                                        textStyle: {
                                            fontSize: 10
                                        },
                                        slantedText: true
                                    },
                                    legend: {
                                        position: 'bottom'
                                    },
                                    animation: {
                                        duration: 1000,
                                        easing: 'out',
                                        startup: true
                                    }
                                },
                                formatters: [
                                    { formatter: new google.visualization.NumberFormat({ pattern: '£###,###.##' }), colIndex: 1 },
                                ]
                            },
                            paymentHistory: this.model.data.PaymentHistory,
                            uninvoicedTransactions: this.model.data.PaymentHistory.filter((item) => item.InvoiceGlobalReference === null),
                            maintenance: (this.settings.productSettings.enableDownloadStatementsSection ||
                                this.settings.productSettings.enableMaintenanceInvoicesSection) && this.buildMaintenance() || [],
                            downloads: this.settings.productSettings.enableDownloadStatementsSection && this.model.data.Statements || []
                        };


                        this.generalTenantData = this.datePlusOneDay(this.model.data.Statements[0].Date);
                        this.grossProjectRental();
                        // Add data after chart is draw in order to use animations
                        this.data['paymentHistoryChart'].data = this.buildPaymentHistory();
                    });
                });
            }
        );
    }

    grossProjectRental() {
        this.propertyService.getSummaryOfExistingTenancies().subscribe(res => {
            res.data.Tenancies.forEach((item: TenancyModel, i) => {
                this.propertyService.getTenancy(
                    { tenancyID: item.ID },
                    { cacheKey: 'tenancy-' + item.ID }
                ).subscribe(data => {
                    if (data.data.ActualEndDate) {
                        const actualEndDate = new Date(data.data.ActualEndDate);

                        if (actualEndDate.toString() !== 'Invalid Date'
                            && actualEndDate.getTime() >= this.dateGeneral.getTime()
                        ) {
                            this.generalTenantsRentAmount += data.data.RentAmount;
                        }
                    }
                });
            });
        });
    }

    buildProfitLoss() {
        // Profit & Loss
        this.apiService.get('profitloss', null, { runInBg: true }).subscribe(
            (respPL: ProfitLossModel) => {
                // Build CSV data
                let data = '';
                const rawData = [respPL.data.Income, respPL.data.DirectCosts, respPL.data.GrossProfitLoss];

                data += 'Title,';
                const rows = respPL.data.DirectCosts.Rows;
                rows[0] && rows[0].MonthTotals.forEach((value) => {
                    data += value.Key + ','; // month's name
                });
                data += 'TOTAL \n';

                rawData.forEach((item, key) => {
                    item.Rows.forEach((elem) => {
                        data += elem.Title + ',';

                        elem.MonthTotals.forEach((value) => {
                            data += value.Value.replace(',', '.').replace('£', '') + ',';
                        });
                        data += elem.Total.replace(',', '.').replace('£', '');
                        data += '\n';
                    });
                    data += '\n';
                });

                return this.profitLossData = {
                    blob: new Blob([data], {
                        type: 'text/csv;charset=utf-8;'
                    }),
                    fileName: 'profitLoss.csv'
                };
            }
        );
    }

    buildMaintenance() {
        // Download invoices
        const maintenance = [];

        this.model.data.Statements.reverse().forEach((invoice) => {
            invoice.Date = this.datePipe.transform(invoice.Date, 'dd/MM/yy');

            invoice['downloadLink'] = this.utilService.buildUrl({
                InvoiceID: invoice.InvoiceID
            }, 'invoice');

            // Filter maintenance invoices
            if (this.settings.productSettings.enableMaintenanceInvoicesSection && invoice.IsMaintenanceInvoice) {
                maintenance.push({
                    Date: invoice.Date,
                    downloadLink: invoice['downloadLink']
                });
            }
        });

        return maintenance;
    }

    buildPaymentHistory() {
        const rows = [];

        this.model.data.PaymentHistory.forEach((value) => {
            if (this.settings.productSettings.enableSelfAssessmentTaxSection) {
                this.reportYears.availableOptions.push(new Date(value.Date).getFullYear());
            }

            value.Date = this.datePipe.transform(value.Date, 'dd/MM/yy');

            // Build rows
            rows.push([value.Date, value.Amount]);
        });

        this.reportYears.availableOptions = this.reportYears.availableOptions.filter((v, i, a) => a.indexOf(v) === i);
        this.greatestYear = this.reportYears.availableOptions[0];

        this.propertyService.getSelfAssessment({yearEnd: this.greatestYear},{}).subscribe(
            res => {
                this.selfAssessment = res.data;
                this.ready = true;
                this.satSection();
            }
        );

        return rows.reverse();
    }

    satSection() {
        // filter unique values
        const data = [];

        this.selfAssessment.forEach((item: any) => {
            data.push({id: item.FileName, name: item.FileName});
        });

        this.reportYears.availableOptions = data;
        this.changeReportYears();
    }

    changeReportYears() {
        if(this.reportYears.selectedOption === null)
            this.reportYears.selectedOption = this.reportYears.availableOptions[0].id;
    }

    buildUrl() {
        const selectedItem = this.selfAssessment.find((item) => {
            return item.FileName === this.reportYears.selectedOption
        });

        const fileContent = new Uint8Array(atob(selectedItem.FileContent).split('').map((char) => char.charCodeAt(0)));

        const blob = new Blob([fileContent], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = selectedItem.FileName;
        link.click();
    }

    datePlusOneDay(data: string)
    {
        let [day, month, year] = data.split("/").map(Number);
        let dateObject = new Date(2000 + year, month - 1, day);

        dateObject.setDate(dateObject.getDate() + 1);

        let updatedDay: any = dateObject.getDate();
        let updatedMonth: any = dateObject.getMonth() + 1;
        let updatedYear = dateObject.getFullYear() - 2000;

        updatedDay = updatedDay.toString().padStart(2, "0");
        updatedMonth = updatedMonth.toString().padStart(2, "0");

        this.dateGeneral = new Date(`${2000 + updatedYear}-${updatedMonth}-${updatedDay}T00:00:00`);
        return `Gross Projected Rental Income from ${updatedDay}/${updatedMonth}/${updatedYear}`;
    }

}
